























































import { Component, Vue } from "vue-property-decorator";
import { Form } from "element-ui";
import {
  UserForm,
  UserFormValidator,
} from "@/app/domains/settings/models/user-form.model";
import CheckboxContainer from "@/app/shared/components/checkbox-container/checkbox-container.vue";
import { NavigationGuardNext } from "vue-router";
import UserRolesValid from "@/app/domains/settings/utils/user-roles-valid";

@Component({
  async beforeRouteUpdate(to, from, next): Promise<void> {
    const instance = this as User;
    await instance.preventRedirect(next);
    if (instance.somethingHasChanged) return;
    instance.getUser(to.params.userId);
    next();
  },
  beforeRouteLeave(to, from, next): void {
    (this as User).preventRedirect(next);
  },
  components: {
    CheckboxContainer,
  },
})
export default class User extends Vue {
  $refs!: {
    userForm: Form;
  };

  private userForm: UserForm = {
    id: "",
    login: "",
    password: "",
    confirmPassword: "",
    roles: [],
  };
  private somethingHasChanged = false;

  private rules: UserFormValidator = {
    login: [
      {
        required: true,
        message: "Please input name",
        trigger: ["blur"],
      },
    ],
    password: [
      {
        min: 6,
        message: "Password must be at least 6 characters",
        trigger: ["blur", "change"],
      },
      {
        validator: (rule, value, callback) => {
          if (this.userForm.confirmPassword && !value) {
            callback("Please input password");
            return;
          }
          callback();
        },
        message: "Please input password",
        trigger: ["change", "blur"],
      },
    ],
    confirmPassword: [
      {
        min: 6,
        message: "Confirm password must be at least 6 characters",
        trigger: ["blur", "change"],
      },
      {
        validator: (rule, value, callback) => {
          if (value !== this.userForm.password && this.userForm.password) {
            callback("The passwords are different");
            return;
          }
          callback();
        },
        message: "The passwords are different",
        trigger: ["change", "blur"],
      },
    ],
  };

  private somethingChanged(): void {
    this.somethingHasChanged = true;
  }

  private async preventRedirect(next: NavigationGuardNext<Vue>): Promise<void> {
    try {
      if (this.somethingHasChanged) {
        await this.$confirm(
          "All changes will not be saved. Are you sure you want to leave this page?",
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        );
        this.somethingHasChanged = false;
      }
      next();
    } catch (error) {
      next(false);
      return;
    }

    if (this.somethingHasChanged) {
      return;
    }
  }

  private async getUser(userId: string): Promise<void> {
    try {
      const { data } = await this.$store.dispatch(
        "settingsModule/getUser",
        userId
      );
      this.userForm.id = data.id;
      this.userForm.login = data.login;
      this.userForm.roles = data.roles;
    } catch (error) {
      this.$emit("invalid-user");
      console.warn("User not found");
    } finally {
      this.userForm.password = "";
      this.userForm.confirmPassword = "";
    }
  }

  private async validateForm(): Promise<boolean> {
    return this.$refs.userForm.validate();
  }

  private async updateUser(): Promise<void> {
    try {
      await this.validateForm();
    } catch (error) {
      return;
    }

    if (!UserRolesValid(this.userForm.roles)) {
      this.$message({
        message: "At least one role must be selected.",
        type: "warning",
      });
      return Promise.reject("At least one role must be selected.");
    }

    try {
      await this.$store.dispatch("settingsModule/updateUser", this.userForm);
      this.somethingHasChanged = false;
      this.$message({
        message: "User updated successfully",
        type: "success",
      });
    } catch (error) {
      console.error(error);
    }
  }

  private async deleteUser(): Promise<void> {
    try {
      await this.$confirm(
        "Are you sure you want to delete the user?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      );
    } catch (error) {
      return;
    }

    try {
      await this.$store.dispatch("settingsModule/deleteUser", this.userForm.id);
      this.$emit("delete-user", this.userForm);
      this.somethingHasChanged = false;
      this.$message({
        message: "User successfully deleted",
        type: "success",
      });
    } catch (error) {
      console.error(error);
    }
  }

  created(): void {
    this.getUser(this.$route.params.userId);
  }
}
